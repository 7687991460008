import Head from "next/head";
import React from "react";
import CookieConsent from "react-cookie-consent";
import { SWRConfig } from 'swr';
import ErrorBoundary from "../components/ErrorBoundary";
import { APP } from "../config/app_config";
import { COMPANY } from "../config/company";
import { fetchData } from "../helpers/utils";
import { sendErrorToSlack } from "../lib/log";
import { Store, } from "../lib/reduxoid";
import initialStoreData from "../store/data";
import { RootReducer } from "../store/reducers";

import 'react-calendar/dist/Calendar.css';

import "../assets/styles/scss/404.scss";
import "../assets/styles/scss/Header.scss";
import '../assets/styles/scss/bootstrap/bootstrap.scss';
import "../assets/styles/scss/dash-header.scss";
import '../assets/styles/scss/progress-circle.scss';
import "../assets/styles/scss/sidebar.scss";
import "../assets/styles/scss/style.scss";
import "../layouts/AuthLayout/AuthLayout.scss";
import "../layouts/DashbaordLayout/DashLayout.scss";
import './../lib/notify/styles/styles.scss';
import './../lib/react-notifications-component/dist/theme.css';
// import '../assets/styles/scss/react-bootstrap-range-slider.scss';
// import '../assets/styles/scss/_react-dates-datepicker.scss';

import Script from "next/script";
import '../components/Radio/_check-and-radio.scss';
import { IS_DEV } from "../config";


if (typeof window !== 'undefined') {
  try {
    if (!IS_DEV) {
      window.addEventListener('error', function (error) {
        let { message, explicitOriginalTarget: url, lineno, colno, error: errorObject } = error;
        sendErrorToSlack(`Error in ${window.location.href} at line ${lineno}: ${colno}`, errorObject);
        return true;
      });
    }
  } catch (e) {
    console.log('error', e)
  }
}

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <title>{APP.appName}</title>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />

        <meta name="description"
          content="OA Accounts is OneAfrica Technologies' single sign on service" />
        <meta name="keywords"
          content="oa,oneafrica,one,africa,accounts,login,sign,in,register,up,send,money,cash,wallet,receive,recipient,pay,application" />
        <meta name="author" content={COMPANY.NAME} />
      </Head>
      <ErrorBoundary>
        <SWRConfig value={{ dedupingInterval: 5000, fetcher: fetchData }}>
          <Store reducer={RootReducer} data={initialStoreData}>
            <Script type="text/javascript" src="https://4059c48e8bb9.eu-west-1.sdk.awswaf.com/4059c48e8bb9/8a65bf9a62a3/challenge.js" async></Script>
            <Component {...pageProps} />
            <CookieConsent enableDeclineButton>
              <h4 className={"text-white"}>Notice</h4>
              <p className={"text-white"}>This website uses cookies to enhance the user experience.</p>
              <p className={"text-white"}>
                We and selected third parties use cookies or similar technologies for technical purposes
                and, with your
                consent, for other purposes as specified in the cookie policy. Denying consent may make
                related features
                unavailable.
                You can consent to the use of such technologies by using the “I understand” button.
              </p>
            </CookieConsent>
          </Store>
        </SWRConfig>
      </ErrorBoundary>
    </>
  )
}

export default MyApp
