import axios from "axios";
import getPlatform from "../platform";
import { COMPANY } from "../../config/company";
const SLACK_WEBHOOK_URL = 'https://hooks.slack.com/services/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';
const APP_URL = typeof window !== "undefined" ? window.location.href : process.env.NEXT_PUBLIC_APP_URL;
const IS_PROD = process.env.NODE_ENV === 'production';

const Style = {
  base : {
    color : '#fff',
    'background-color' : '#444',
    padding : '2px 4px',
    'border-radius' : '2px'
  },
  error : {
    'background-color' : '#f00',
    'color' : '#fff',
    icon : '✖'
  },
  warning : {
    color : '#eee',
    'background-color' : '#ffa908',
    icon : '⚠'
  },
  success : {
    'background-color' : '#b0d705',
    'color' : '#fff',
    "font-weight" : "bold",
    icon : '✔'
  },
  info : {
    'background-color' : '#00b0ff',
    'color' : '#fff',
    'icon' : 'ℹ',
    padding : '2px',
    fontWeight : 'bold',
  }
}

let toStyleArray = (style) => {
  let styleArray = []
  Object.entries(style).forEach(([ key, value ]) => {
    styleArray.push(`${key}: ${value}`)
  });

  return styleArray
}

let creatMessageString = (message) => {
  let messageString = ''

  if (typeof message === 'string') {
    messageString = message
  } else if (Array.isArray(message)) {
    message.forEach(item => {
      if (typeof item === 'string') {
        messageString += "\t" + item
      } else if (typeof item === 'object') {
        messageString += "\t" + JSON.stringify(item)
      } else if (Array.isArray(item)) {
        messageString += "\t" + item.join(", ");
        // messageString += "\t" + creatMessageString(item);
      }
    })
  } else if (typeof message === 'object') {
    messageString = JSON.stringify(message, null, 2)
  } else {
    messageString = message.toString()
  }

  return messageString;
}

function objToString (object, bullet = ':diamonds:', mapper = ' :arrow_right: ') {
  var str = '';
  for (var k in object) {
    if (object.hasOwnProperty(k)) {
      str += `${bullet}\t${k}\t${mapper} ${object[k]} \n`;
    }
  }
  return str;
}

export const sendMessageToSlack = async (message, content) => {
  try {
    const slackMessage = {
      channel: '#oa-auth-fr-logs',
      text: `Message from ${COMPANY.APP}`,
      author_name: ' OA Pay FR',
      "blocks": [
        {
          "type": "header",
          "text": {
            "type": "plain_text",
            "text": `:information_source: ${message}`
          }
        },
        {
          "type": "section",
          "block_id": "section567",
          "text": {
            "type": "mrkdwn",
            "text": `<${APP_URL}|${APP_URL}>`
          },
        },
        { "type": "divider" },
      ],
      attachments: [
        {
          color: Style.success["background-color"],
          title: 'Message',
          text: message,
          fields: [
            {
              title: 'Type',
              value: "Message",
              short: true
            },
            {
              title: 'Stack Text',
              value: typeof content !== "string" ? objToString(content) : content,
              short: false
            }
          ]
        },
      ],
    };

    await axios.post(SLACK_WEBHOOK_URL, slackMessage, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });
  } catch (e) {
    console.error(e);
  }
}

export const sendErrorToSlack = async (message, error) => {
  try {
    let appUrl = process.env.NEXT_PUBLIC_APP_URL;
    const slackMessage = {
      channel: '#oa-pay-fr-error-logs',
      text: `An error occurred: in ${COMPANY.APP}`,
      author_name: ' OA Pay FR',
      "blocks": [
        {
          "type": "header",
          "text": {
            "type": "plain_text",
            "text": `:warning: ${message}`
          }
        },
        {
          "type": "section",
          "block_id": "section567",
          "text": {
            "type": "mrkdwn",
            "text": `<${appUrl}|${appUrl}>`
          },
        },
        { "type": "divider" },
        {
          "type": "section",
          "block_id": "section789",
          "fields": [
            {
              "type": "mrkdwn",
              "text": `*${error.name}*\n${error.message}`
            }
          ]
        },
        {
          "type": "section",
          "block_id": "section7895",
          "fields": [
            {
              "type": "mrkdwn",
              "text": objToString(getPlatform())
            }
          ]
        },
        { "type": "divider" },
      ],
      attachments: [
        {
          color: Style.error["background-color"],
          title: 'Trace',
          text: error ? error.stack : null,
          fields: [
            {
              title: 'Type',
              value: "Error",
              short: true
            },
            {
              title: 'Message',
              value: message,
              short: true
            },
            // {
            //   title: 'Stack Text',
            //   value: error ? objToString(error.stack) : null,
            //   short: true
            // }
          ]
        },
      ],

    };

    await axios.post(SLACK_WEBHOOK_URL, slackMessage, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });
  } catch (e) {
    console.error(e);
  }
}

/**
 *
 * @param message {string|array|object}
 * @param type {string}
 * @param error {Error}
 * @param style {object}
 */
export default function log (message, type = "base", error = null, style = {}) {
  type = type.toLowerCase();
  let styleArray = toStyleArray({
    padding : '2px',
    'font-weight' : 'bold',
    ...Style[type],
    ...style
  });

  message = creatMessageString(message);
  // if (error && IS_PROD) {
  if (error) {
    try {
      sendErrorToSlack(message, error);
    } catch (e) {
      console.warn(e);
    }
  }
  console.log(`%c${message}`, `${styleArray.join(';')}`, error);
}
