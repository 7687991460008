import { COMPANY } from "./company";

export const APP = {
  appName : 'One Africa Accounts',
  appAlias : 'OA Accounts',
  appCodeName : 'OA Auth',
  appVersion : '1.0.0',
  appDescription : '',
  appAuthor : COMPANY.NAME,
  appAuthorUrl : 'https://oamarkets.com',
  appAuthorEmail : '',

  playStoreUrl : 'https://play.google.com/store/apps/details?id=com.oamarkets.oapay',
  appStoreUrl : 'https://itunes.apple.com/us/app/oapay/id1498465890?mt=8',
}
