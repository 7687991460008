import app from "./models/app-data";
import user from "./models/user";
import appearance from "./models/appearance";

export {
  app,
  user,
  appearance
}


export default {
  app,
  user,
  appearance
}
