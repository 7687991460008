export const AppearanceReducer = function AppearanceReducer (state, action) {
  let {type, payload} = action;

  switch (type) {
    case "UPDATE_THEME" : {
      return {
        ...state, theme : payload // light | dark | system
      }
    }
    case "UPDATE_DIRECTION" : {
      return {
        ...state, direction : payload // ltr | rtl
      }
    }
    case "UPDATE_SIDEBAR_OPEN" : {
      return {
        ...state, sideBarOpen : payload
      }
    }
    case "UPDATE_SIDEBAR_COLLAPSED" : {
      return {
        ...state, sideBarCollapsed : payload
      }
    }
    default :
      return state;
  }
}

export default AppearanceReducer;
