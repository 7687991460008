export default {
  lang : 'en-GH',
  idToken : null,
  accessToken : null,
  refreshToken : null,
  token : null,
  primaryCurrency : '',
  notifications : [],
  messages : [],
};
