export const UserReducer = function UserReducer (state, action) {
  let { type, payload } = action;

  switch (type) {
    case "UPDATE_USER" : {
      return {
        ...state, ...payload
      }
    }
    case "UPDATE_PICTURE" : {
      return {
        ...state, picture : payload
      }
    }
    case "UPDATE_GIVEN_NAME" : {
      return {
        ...state, given_name : payload
      }
    }
    case "UPDATE_FAMILY_NAME" : {
      return {
        ...state, family_name : payload
      }
    }
    default :
      return state;
  }
}

export default UserReducer;
