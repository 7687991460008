export const AppDataReducer = function AppDataReducer (state, action) {
  let {type, payload} = action;

  switch (type) {
    case "UPDATE_BASE_CURRENCY" : {
      return {
        ...state, baseCurrencyCode : payload
      }
    }
    case "UPDATE_SIDEBAR_OPEN" : {
      return {
        ...state, sideBarOpen : payload
      }
    }
    case "UPDATE_SIDEBAR_COLLAPSED" : {
      return {
        ...state, sideBarCollapsed : payload
      }
    }
    default :
      return state;
  }
}



export default {
  UPDATE_BASE_CURRENCY : "baseCurrencyCode",
  UPDATE_SIDEBAR_OPEN : "sideBarOpen",
  UPDATE_SIDEBAR_COLLAPSED : "sideBarCollapsed"
};


