import React from "react";
import { string, stripChars } from "./string";
import * as classes from 'classnames'
import { Col, FormGroup, Input, Row } from "reactstrap";
import Swal from '@sweetalert/with-react'
import axios from "axios";
import log from "../../lib/log";
import useSWR, { cache } from "../../lib/swr/dist";
// import Radio from "../../components/Radio/Radio";
// import ComboBox from "../components/ComboBox/ComboBox";
// import DatePicker from "../components/DatePicker/DatePicker";
// import Swal from 'sweetalert'

/**
 *
 * @param str
 * @returns {boolean|boolean}
 */
export const isEmpty = function isEmpty (str) {
  return null === str || undefined === str ? true : /^[\s\xa0]*$/.test(str);
};

/**
 *
 * @param strArr {Array<String>}
 */
export const allEmpty = function allEmpty (strArr) {
  return strArr.every(isEmpty)
};

/**
 *
 * @param strArr {Array<String>}
 */
export const someEmpty = function allAreEmpty (strArr) {
  return strArr.some(isEmpty)
};

export const isNumber = function (value) {
  return typeof value === 'number' && !isNaN(value);
};

export const toSentenceCase = function (str) {
  str = str.toString().toLowerCase();
  return str = str[0].toUpperCase() + str.substring(1, str.length);
};

/**
 *
 * @param object
 * @returns {boolean}
 */
export const objectIsEmpty = function (object) {
  return Object.keys(object).length < 1;
};

export const extend = function (dest = {}, src, strict) {

  let copySrcToDest = function (dest, src) {
    for (let key in src) {
      if (strict) {
        if (src.hasOwnProperty(key)) {
          dest[key] = src[key];
        }
      } else {
        dest[key] = src[key];
      }
    }
  };

  if (!(Array.isArray(src))) {
    copySrcToDest(dest, src);
  } else {
    src.forEach(function (src) {
      copySrcToDest(dest, src);
    });
  }
  copySrcToDest = null;
  return dest;
};

export const update = function (dest = {}, src, strict) {

  let copySrcToDest = function (dest, src) {
    for (let key in src) {
      if (strict) {
        if (src.hasOwnProperty(key)) {
          dest[key] = src[key];
        }
      }
    }
  };

  if (!(Array.isArray(src))) {
    copySrcToDest(dest, src);
  } else {
    src.forEach(function (src) {
      copySrcToDest(dest, src);
    });
  }
  copySrcToDest = null;
  return dest;
};

/**
 * @use This enables finding a field and it's related data in a given form configurations (Aka Form field sets)
 * @param items {Array}
 * @param name {String}
 * @param  {function} [callback] An optional callback function called if available when a field is found
 * @returns {*}
 */
export const searchField = (items, name, callback) => {
  let rows = items.flat()
    .filter(item => Array.isArray(item))
    .flat().map(section => section.rows).flat().flat();

  for (let i = 0, len = rows.length; i < len; i++) {
    let field = rows[i];
    if (field.name === name) {
      return field
    }
  }
};

/**
 *
 * @param formFieldSets
 * @returns {unknown[] | any[]}
 */
export const getFieldsFromSteps = (formFieldSets) => {
  return formFieldSets.flat()
    .filter(item => Array.isArray(item))
    .flat().map(section => section.rows).flat().flat();
};

/**
 *
 * @param items {Array}
 * @param name {String}
 * @returns {*}
 */
export const searchAndUpdateFieldItem = (items, name, key, newValue) => {
  let rows = items.flat()
    .filter(item => Array.isArray(item))
    .flat().map(section => section.rows).flat().flat();

  for (let i = 0, len = rows.length; i < len; i++) {
    let field = rows[i];
    if (field.name === name) {
      field[key] = newValue;
      return items;
    }
  }
};

/**
 * @use Used to create the
 * @param field
 * @param formData
 * @param formFieldSets
 * @param context This should be the [this] of the component in which createFormSteps was called so that form-field event handlers can be
 * @returns {*}
 */
export const createInputField = (field, formData, formFieldSets, context) => {
  // let { formData } = this.props;
  let fieldElement = null;
  let { type, name } = field;
  // console.log(field);
  if (type === "radio" && field.options.length > 0) {
    fieldElement = (
      <Row className="justify-content-between">
        {field.options.map((option, o) => {
          let fdValue = formData[name];
          let checked = false;

          if (fdValue === undefined || fdValue === null || fdValue === false || fdValue === "") {
            checked = !!(option.default && option.default === true);
          } else if (fdValue === option.value) {
            checked = true;
          }

          // console.log({ field });
          return (
            <Radio
              className={"col-6 " + (field.error ? "error" : "")}
              key={o}
              id={name + o}
              name={name}
              label={option.label}
              defaultChecked={checked}
              value={option.value}
              onChange={(name, value) => {
                context[field.handleChange](name, value);
              }}
            />
          );
        })}
      </Row>
    );
  } else if (field.type === "ckeckbox") {
    // TODO Implement Showing saved form state for the radio buttons too
    fieldElement = (
      <Radio
        className={"col-6 " + (field.error ? "error" : "")}
        id={field.id}
        name={name}
        label={field.label}
        value={formData[name]}
        handleChange={context[field.handleChange]}
      />
    );
  } else if (type === "date" || type === "date-picker") {
    fieldElement = (
      <DatePicker
        id={field.id}
        dataPropName="id"
        url={"#"}
        defaultDate={formData[name]}
        name={name}
        value={formData[name]}
        label={field.label}
        placeholder={field.placeholder}
        preloaded={false}
        labelPropName="label"
        valuePropName="value"
        maxDate={field.maxDate}
        startDate={field.startDate}
        disabled={field.disabled}
        className={"" + (field.error ? " border-danger" : "") + (field.disabled ? " disabled" : "")}
        handleChange={(name, value) => {
          context[field.handleChange](name, value);
        }}
      />
    );
  } else if (type === "combo-box") {
    let items = searchField(formFieldSets, name);
    // console.log(name);
    fieldElement = (
      <ComboBox
        id={field.id}
        dataPropName="id"
        url={"#"}
        items={items.options}
        defaultItem={field.default || formData[name]}
        name={name}
        value={field.value}
        label={field.label}
        placeholder={field.placeholder}
        preloaded={false}
        labelPropName="label"
        valuePropName="value"
        disabled={field.disabled}
        className={"" + (field.error ? " border-danger" : "") + (field.disabled ? " disabled" : "")}
        handleChange={(name, option) => {
          context[field.handleChange](name, option);
        }}
      />
    );
  } else if (type === "display-text") {
    fieldElement = null;
    if (Array.isArray(formData[name])) {
      fieldElement = formData[name].map((text, i) => {
        return (<p id={field.id + i} key={field.id + i} style={field.style || null}
                   className={classes("mb-0 display-text", field.className || "")}>{text || ""}</p>)
      })
    } else {
      let fieldCase = '';
      fieldElement = <p id={field.id} style={field.style || null}
                        className={classes("mb-0 display-text", field.className || "",
                          (field.case ? 'text-' + field.case : 'text-capitalize'), { 'p-0' : field.align })}>
        {field.prefix ? (<span>{field.prefix} </span>) : null}{(formData[name]) || "—"}
      </p>
    }
  } else {
    // (type === 'text' || number|| email) etc
    // console.log(name, { context, fB: field.onBlur });
    fieldElement = (
      <Input
        id={field.id}
        name={name}
        value={formData[name]}
        placeholder={field.placeholder}
        type={type}
        readOnly={field.readOnly}
        style={{ borderRadius : "0" }}
        className={"" + (field.error ? " border-danger" : "")}
        onChange={context.handleTextChange.bind(context)}
        onBlur={field.onBlur ? context[field.onBlur].bind(context) : null}
      />
    );
  }
  return fieldElement;
};

/**
 * @use Tha function is used to generate forms from form-configuration objects
 *  It designed the support "stepped" forms by default.
 *  Steps contain rows
 * @param formFieldSets
 * @param {Number} [currentStep]
 * @param formData
 * @param context This should be the [this] of the component in which the form steps are to be rendered
 * @returns {*}
 */
export const createFormSteps = (formFieldSets, currentStep = 1, formData, context) => {
  let inputField = createInputField;
  return formFieldSets.map((step, i) => {
    let stepSections = step[1];
    let footer = step[2];
    return (
      <div className={"form-step text-left " + (currentStep - 1 !== i ? "d-none" : "")} key={i}>
        {/* msg render step*/
          stepSections.map((section, s) => {
            return (
              // render a section in a step
              <div key={s}>
                {section.label && <h5 className="my-3 form-section-title" key={s}>{section.label}</h5>}
                {section.rows.map((row, r) => {
                  return (
                    <Row className="justify-content-start" key={r}>
                      {row.map((field, c) => {
                        // render rows of inputs in the section
                        let fieldElement = null;

                        if (!Array.isArray(field)) {
                          fieldElement = inputField(field, formData, formFieldSets, context);
                        } else {
                          let fields = field;
                          fieldElement = fields.map((field, cf) => {
                            return inputField(field, formData, formFieldSets, context);
                          });
                        }

                        let colSpan = Math.floor(12 / row.length);
                        if (field.colSpan) {
                          colSpan = 4 * field.colSpan;
                        }
                        return (
                          <Col md={colSpan} key={c}
                               className={
                                 classes({ 'd-flex justify-content-between' : field.align })}>
                            {
                              field.label &&
                              <label htmlFor={field.id}
                                     className={classes("d-block text-capitalize text-gray text-small font-weight-bold mb-2", { "disabled" : field.disabled })}>
                                {field.label} {field.required && <span className='text-danger'>*</span>}
                              </label>
                            }
                            <FormGroup
                              className={classes(field.className || "", { 'p-0 align' : field.align === true }, "text-black")}>
                              {fieldElement}
                              {field.errorMessage ? <p
                                className={classes('input-message', { 'text-danger' : field.showErrorMessage === true })}>{field.errorMessage}</p> : null
                              }</FormGroup>
                          </Col>
                        );
                      })}
                    </Row>
                  );
                })}
              </div>
            );
          })}
        {
          footer ? footer : ''
        }
      </div>
    );
  })
};

export const objectMap = (obj, f, opt_obj) => {
  var res = {};
  for (var key in obj) {
    res[key] = f.call(/** @type {?} */ (opt_obj), obj[key], key, obj);
  }
  return res;
};

export const toDecimalPlaces = (value, decimalPlaces = 2) => {
  let val = val
};

/**
 *
 * @param options {{title : String, text : String, icon: String, content : HTMLElement, handleDismiss : Function, handleSecondaryButton : Function, handlePrimaryButton : Function}}
 */
export const diceyDialog = (options = {
  icon : "info",
  handleDismiss : undefined, handleSecondaryButton : undefined, handlePrimaryButton : undefined
}) => {
  //"warning", "error", "success" and "info".
  Swal({
    title : null,
    text : "",
    // timer: 6000,
    timerProgressBar : false,
    // buttons: null, //buttons: ["Oh noez!", "Aww yiss!"],
    closeOnEsc : false,
    closeOnClickOutside : false,

    ...options
  })
    .then((value) => {
      if (value === true) {
        options.handlePrimaryButton && options.handlePrimaryButton(value)
      }
      if (value === false) {
        options.handleSecondaryButton && options.handleSecondaryButton(value)
      }
      if (value === null) {
        options.handleDismiss && options.handleDismiss(value)
      }
    });
}

export const noop = () => {
}

export function removeTrailingCharacter (str, char = "0", all = false) {
  /**
   * Removes the trailing slash of a path if there is one. Preserves the root path `/`.
   */
  if (!all) {
    return str.endsWith(char) ? str.slice(0, -1) : str;
  }
}

export function removePathTrailingHashPound (path) {
  if (path.endsWith("#")) {
    path = path.replace(new RegExp('#' + '$'), '');
  }
  return path
}

export function removePathTrailingSlash (path) {

  if (path !== "/") {
    return removeTrailingCharacter(path, "/");
  }
  return path
}

/**
 *
 * @param path {String}
 * @returns {string|*}
 */
export function removeAllPathTrailingSlashes (path) {
  if (path !== "/") {
    let newPath = path;
    let j = path.length - 1;

    while (j >= 0) {
      let len = newPath.length;
      if (newPath.endsWith("/")) {
        newPath = newPath.substring(0, len - 1)
        j--
      } else {
        break;
      }
    }
    return newPath;
  }
  return path
}

export const cleanUrl = function (url) {
  return removeAllPathTrailingSlashes(removePathTrailingHashPound(url))
}

/**
 *
 * @param {String} url
 * @param {Boolean} isQueryString
 * @returns {{}}
 */
export const getQueryParams = function (url, isQueryString = false) {
  if (url || typeof window !== "undefined") {
      url = url ? url : typeof window !== "undefined" ? decodeURI(cleanUrl(window.location.href)) : "";

    let params = {};
    if (url.endsWith("#")) {
      url = url.replace(new RegExp('#' + '$'), '');
    }
    let queryString = isQueryString ? url : url.split('?')[1];

    if (!queryString) {
      return params;
    }

    let queryArray = queryString.split('&');

    queryArray.forEach((param,) => {
      let [ key, value ] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
    });

    url = queryString = queryArray = null;

    return params;
  }
  return {}
}

/**
 *
 * @param params {Object}
 * @returns {string}
 */
export const createQueryString = (params) => {
  let queryParams = "";

  if (params) {
    Object.entries(params)
      .forEach(([ key, value ], i) => {
        queryParams += (i > 0 ? "&" : "") + key + "=" + value
      })
  }
  return encodeURI(queryParams)
}

/*export const getQueryParams = function (url) {
  if (url || typeof window !== "undefined") {
    url = url ? url : window.location.href;
    let { query } = parse(url, true);
    return query
  }
   return query
}*/

export function decodeFromBase64 (str) {
  // Going backwards: from byte stream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

export function encodeToBase46 (str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
    function toSolidBytes (match, p1) {
      return String.fromCharCode('0x' + p1);
    }));
}

/**
 * Checks if `value` is the
 * [language type](http://www.ecma-international.org/ecma-262/7.0/#sec-ecmascript-language-types)
 * of `Object`. (e.g. arrays, functions, objects, regexes, `new Number(0)`, and `new String('')`)
 *
 * @static
 * @memberOf _
 * @since 0.1.0
 * @category Lang
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is an object, else `false`.
 * @example
 *
 * _.isObject({});
 * // => true
 *
 * _.isObject([1, 2, 3]);
 * // => true
 *
 * _.isObject(_.noop);
 * // => true
 *
 * _.isObject(null);
 * // => false
 */
export const isObject = function isObject (value) {
  var type = typeof value;
  return value != null && (type === 'object' || type === 'function');
}

export const isDefined = function (value) {
  return typeof value !== "undefined";
}

export const getJwtToken = function () {
  if (typeof window !== "undefined") {
    return localStorage.getItem(process.env.NEXT_PUBLIC_X_AUTH_LABEL);
  }
}

export const removeJwtToken = function () {
  if (typeof window !== "undefined") {
    return localStorage.removeItem(process.env.NEXT_PUBLIC_X_AUTH_LABEL);
  }
}

export const getAccessToken = function () {
  let jwt = getJwtToken();
  if (jwt) {
    return (JSON.parse(jwt))['accessToken'];
  }
  return false;
}

export const getIdToken = function () {
  let jwt = getJwtToken();
  if (jwt) {

    return (JSON.parse(jwt))['idToken'];
  }
  return false;
}

export const getNewTokenByInterval = function (refreshInterval) {
  // TODO make sure this is not done when the app is in idle mode
  let interval;
  interval = setInterval(async () => {
    try {
      await renewAccessToken();
    } catch (error) {
      clearInterval(interval);
    }

  }, refreshInterval);
}

/**
 *
 * @returns {Promise<void>}
 */
export const renewAccessToken = async function () {
  try {
    let token = JSON.parse(getJwtToken());
    let { refreshToken } = token;

    let response = await axios.post('/login/renew-token', {
      refreshToken
    });

    if (response) {
      let { data : { data : { accessToken, idToken } } } = response;
      if (response.status === 200) {
        let token = JSON.stringify({
          refreshToken,
          accessToken,
          idToken
        });
        axios.defaults.headers.common['x-auth-token'] = accessToken;
        localStorage.setItem(process.env.NEXT_PUBLIC_X_AUTH_LABEL, token);
      }

      return {accessToken, idToken,}
    }
  } catch (error) {
    handleNetworkError(error, {
      404 : function (error) {
        console.log(error);
      },
      401 : () => {
      }
    })
  }
}

/**
 *
 * @param error {Error}
 * @param [handlers] {{}}
 * @param [code] {String}
 * @param [isOnline] {String}
 * @param [url] {String}
 */
export const handleNetworkError = function (error, handlers = {}, code = "", isOnline = "", url = "",) {
  log(`${error.message} ${code}`, "error");

  if (error.response) {
    // localising the status object for faster lookup
    let { response : { status, data } = {} } = error;
    if (status === 404) {
      if (typeof handlers[404] !== "function") {
        if (typeof isOnline !== "undefined" && isOnline === false) {
          // let the user know that the page is not found
          diceyDialog({
            icon : "error",
            title : "Error",
            text : "It appears you're offline, please check your network connection and try again",
            timer : null,
          });
        } else {
          diceyDialog({
            title : "Error",
            text : "An Error occurred at our end. Please give it another try in a few moments.",
            timer : null, icon : "error"
          })
        }
      } else {
        handlers[404](data);
      }

    } else if (status === 400) {
      if (typeof handlers[400] !== "function") {
        diceyDialog({
          icon : "error",
          title : "Error",
          text : data.message,
          timer : null,
        });
      } else {
        handlers[400](data);
      }

    } else if (status >= 500 && status < 600) {
      if (typeof handlers[status] !== "function") {
        diceyDialog({
          title : "Error",
          text : "It's not you, it's us. Please give it another try in a few moments.",
          timer : null, icon : "error"
        })
      } else {
        handlers[status](data);
      }

    } else if (Object.keys(handlers).length > 0) {
      console.log(handlers, status);
      if (typeof handlers[status] === "function") {
        handlers[status](data);
      }
    }
  } else {
    if (error.message === "Network Error") {
      if (typeof handlers["NetworkError"] !== "function") {
        diceyDialog({
          title : "Network Error",
          text : "Please check your network connection and try again",
          timer : null, icon : "error"
        })
      } else {
        handlers["NetworkError"]({message : error.message});
      }

    } else {
      // display generic error
      diceyDialog({
        icon : 'error',
        title : 'Error',
        text : "Sorry, an error occurred. Please try again in a few moments",
      });
    }
  }
}

/**
 *
 * @returns {Promise<void>}
 */
const invalidTokenLogout = ({ code, message, name }) => {
  localStorage.removeItem(process.env.NEXT_PUBLIC_X_AUTH_LABEL);

  // let {code, message, name} = data;
  if (message === "Access Token has expired") {
    router.push(`/login?i_redirect_url=${router.pathname}${se}`);
  }
}

/**
 *
 * @returns {Promise<void>}
 */
/*export const logout = function (currentPath = '', router, sessionTimeout = false ) {

  if (isEmpty(currentPath) && !isEmpty(router)) {
    currentPath = router.basePath + router.pathname;
  } else {
    currentPath = window.encodeURI(window.location.href);
  }

  let queryParams = createQueryString({ ...(sessionTimeout && ({se : 1})), a_redirect_url : currentPath, source : "settlement" });
  queryParams = !isEmpty(queryParams) ?  "?" + queryParams : ""

  try {
    localStorage.removeItem(process.env.NEXT_PUBLIC_X_AUTH_LABEL);

    window.location.href =
      removeAllPathTrailingSlashes(
        window.encodeURI(`${process.env.OA_AUTH_PATH}/login${queryParams}`)
      );
  } catch (e) {
    window.location.href =
      removeAllPathTrailingSlashes(
        window.encodeURI(`${process.env.OA_AUTH_PATH}/login?${queryParams}`)
      );
  }
}*/

export const logout = async function (router, sessionTimeout = false) {
  let queryString = createQueryString({ ...(sessionTimeout && { se : 1, i_redirect_url : router.pathname }) });
  try {
    localStorage.removeItem(process.env.NEXT_PUBLIC_X_AUTH_LABEL);
    router.push(`/login?${queryString}`);
  } catch (error) {
    router.push(`/login?${queryString}`);
  }
}

export const createPreviewImage = function (image) {
  if (typeof window !== "undefined") {
    const URL = window.URL || window.webkitURL;

    if (URL) {
      return URL.createObjectURL(image);
    }
  }
  return null
}

function readFile (file) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

/**
 *
 * @param name
 * @param event
 * @param {Function} [onChange]
 * @returns {{image: *, imageFile}}
 */
export const handleImageFileInputChange = async function (name, event, onChange) {
  let files = event.target.files;

  if (files && files.length) {
    let imageFile = files[0];

    if (/^image\/\w+/.test(imageFile.type)) {
      let image = createPreviewImage(imageFile);

      let imageDataUrl = await readFile(imageFile);

      // apply rotation if needed

      const ORIENTATION_TO_ANGLE = {
        '3' : 180,
        '6' : 90,
        '8' : -90,
      }
      // const orientation = await getOrientation(imageFile)
      // const rotation = ORIENTATION_TO_ANGLE[orientation]
      // if (rotation) {
      //   imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      // }

      if (typeof onChange === 'function') {
        onChange(name, imageFile, event)
      }

      return { image, imageFile : files[0], imageDataUrl }
    }
  }
}

/*export const uploadFile = async function (path, name, file, onUploadProgress) {
  let formData = new FormData();

  formData.append(name, file, ".png");

  onUploadProgress = typeof onUploadProgress === 'function' ? onUploadProgress : noop

  // console.log({ formData });
  // for (let [ key, value ] of formData.entries()) {
  //   console.log({ key, value });
  // }
  return await axios.post(path, formData, {
    headers : {
      "Content-Type" : "multipart/form-data",
      'x-auth-token' : getAccessToken()
    },
    onUploadProgress
  })
}*/

export const uploadFile = async function (path, name, file, onUploadProgress) {
  let formData = new FormData();

  formData.append(name, file, ".png");

  onUploadProgress = typeof onUploadProgress === 'function' ? onUploadProgress : noop

  return await axios.post(path, formData, {
    headers : {
      "Content-Type" : "multipart/form-data",
      'x-auth-token' : getAccessToken()
    },
    onUploadProgress
  })
}

let normalisePhoneNumber = function (phoneNumber, countryCode = '233') {
  phoneNumber = stripChars(phoneNumber, [ /^[\s\xa0]*$/, " ", "\t", ], "").trim();
  let length = phoneNumber.length;

  if (length === 10) {
    return `+${countryCode}${phoneNumber.substring(1, length)}`
  } else if (length === 9) {
    return `+${countryCode}${phoneNumber}`
  } else if (length === 12) {
    return `+${phoneNumber}`
  }
  return phoneNumber
}

export const fetchData = async (url) => {
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (err) {
    let { response : { status, data } = {} } = error;
    status === 401 && logout(null, null, true);

    throw err;
  }
};

export const useStaleSWR = (dataKey, fetcher = fetchData, options = {}) => {
  const revalidationOptions = {
    revalidateOnMount : !cache.has(dataKey), //here we refer to the SWR cache // EXPLORE explore JIT cache import
    revalidateOnFocus : false,
    revalidateOnReconnect : false,
    ...options
  };

  return useSWR(dataKey, fetcher, revalidationOptions);
}

export const replaceCharToLastBut = (str, lastBut = 3, replaceWith = "*") => {
  str = str.split("");
  let i = str.length - (lastBut + 1);
  for (; i >= 0; i--) {
    str[i] = replaceWith;
  }

  return str.join("")
}

export const obscurePhone = function (phone, hideWith = "*") {

  return replaceCharToLastBut(phone, 3, hideWith);
}

export const obscureEmail = function (email, hideWith = "*") {
  email = email.split("@");
  return `${replaceCharToLastBut(email[0], 4, hideWith)}@${email[1]}`;
}

export const redirectTo = (path) => {
  window.location.href = path;
}

export const hasClientToken = () => {
  if (typeof window === 'undefined') {
    return false
  }
  return localStorage.getItem(process.env.NEXT_PUBLIC_X_AUTH_LABEL);
}

export const setUITheme = (dispatch, appearance, theme) => {
  if (appearance?.theme) {
    dispatch({ type : 'appearance/UPDATE_THEME', payload : theme });
  }
}

export const listHasMatchInCheckList = (list, checkList) => {
  let hasMatch = false;
  if (Array.isArray(list) && Array.isArray(checkList)) {
    for (let i = 0, len = list.length; i < len; i++) {
      if (checkList.includes(list[i])) {
        hasMatch = true;
        break;
      }
    }
  }

  return hasMatch;
}


