import rootReducer, { AppDataReducers, UserReducers, AppearanceReducers } from '../actions/root';

const reducers = rootReducer;

let createRootReducer = function (reducers) {

  let rootReducer = {}

  reducers.forEach(function (model) {

  });
}

export const RootReducer = function RootReducer (state, action) {
  let { type, payload } = action;

  type = type.split("/") // if it's namespaced, namespace will be the first el in the array-list

  if (type.length > 1) {
    let namespace = type[0], mutation = type[1];

    if (reducers[namespace]) {
      let updateKey = reducers[namespace]["mutations"];
      if (updateKey) {
        return {
          ...state,
          [namespace] : reducers[namespace]["mutations"](state[namespace], { type : mutation, payload }),
        }
      }
    }
  } else if (reducers[type[0]]) {
    return {
      ...state, [reducers[type[0]]] : payload
    }
  } else {
    return state;
  }
}


