import { AppDataReducer as AppDataReducers } from './mutators/AppData'
import { UserReducer as UserReducers } from './mutators/User'
import { AppearanceReducer as AppearanceReducers } from './mutators/Appearance'

export { AppDataReducers, UserReducers, AppearanceReducers }

export default {
  app : {
    namespace : 'app',
    namespaced : true,
    mutations : AppDataReducers
  },
  user : {
    namespace : 'user',
    namespaced : true,
    mutations : UserReducers
  }
  ,
  appearance : {
    namespace : 'appearance',
    namespaced : true,
    mutations : AppearanceReducers
  },
}
