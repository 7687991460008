export const COMPANY = {
  NAME : 'OneAfrica Technologies',
  FULL_NAME : 'OneAfrica Technologies Ltd',
  APP : 'OA Accounts',
  PLATFORM : 'OneAfrica',
  ADDRESS : 'OneAfrica Technologies Ltd, UK',
  FULL_ADDRESS : {
    'Registered Office' : [
      'OneAfrica Technologies Ltd, UK ',
      'Colony 5 Piccadilly Place',
      'Manchester, M1 3BR'
    ],
    'Email' : 'info@oneafrica.io',
  },
  PHONE : '+44 0333 772 2310',
  EMAIL : 'info@oneafrica.io',
  WEBSITE : '',
  FACEBOOK : '',
  TWITTER : '',
  INSTAGRAM : '',
  YOUTUBE : '',
  LINKEDIN : '',
}
